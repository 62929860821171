export enum VEHICLE_USE_TYPES {
  PRIVATE = 'PRIVATE',
  HIRING = 'HIRING',
  RENT = 'RENT',
}

export enum CATEGORIES_LIST {
  CLASSES = 'Classes',
  COMMISSIONS = 'Commissions',
  TYPES = 'Types',
  SUB_CLASSES = 'Sub-Classes',
  COVERS = 'Covers',
  COVERS_TRV = 'Covers (TRV)',
  COVERS_MTR = 'Covers (MTR)',
  SUBLIMITS_MTR = 'Sublimits (MTR)',
  SUBLIMITS_MED = 'Sublimits (MED)',
  SUBLIMITS_TRV = 'Sublimits (TRV)',
  DISCOUNTS = 'Discounts',
  VEHICLE_MAKES = 'Vehicle Makes',
  VEHICLE_MODELS = 'Vehicle Models',
  VEHICLE_TYPES = 'Vehicle Types',
  CHECKLIST_DOCUMENTS = 'Document Types',
  FUEL_TYPES = 'Fuel Types',
  NATURE = 'Natures',
  COVERAGES = 'Coverages',
  COVERAGE_TYPES = 'Coverage Types',
  PLANS = 'Plans',
  SECTIONS = 'Sections',
  COUNTRIES = 'Countries',
}

export enum FUEL_TYPES_FIELDS {
  TYPE = 'TYPE',
}

export enum VEHICLE_TYPES {
  CAR = 'CAR',
  VAN = 'VAN',
  LORRY = 'LORRY',
  BUS = 'BUS',
}

export enum FINANCIAL_INTEREST_TYPES {
  LEASE = 'LEASE',
  LOAN = 'LOAN',
  HIRE = 'HIRE',
  NONE = 'NONE',
}

export enum COMMUNICATION_MODES {
  EMAIL = 'EMAIL',
  LETTERS = 'LETTERS',
  FAX = 'FAX',
}

export enum COMMUNICATION_LANGUAGES {
  SINHALA = 'SINHALA',
  ENGLISH = 'ENGLISH',
  TAMIL = 'TAMIL',
}

export enum COVER_TYPES {
  COMPREHENSIVE = 'COMPREHENSIVE',
  THIRD_PARTY = 'THIRD PARTY FIRE THEFT',
  THIRD_ONLY = 'THIRD ONLY',
}

// For Dropdowns
export enum FUEL_TYPES {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
}

// for evaluate proposal
export enum LOADING_TYPES {
  MED_RISK_LOADING = 'RISK LOADINGS (MED)',
  MTR_RISK_LOADING = 'RISK LOADINGS (MTR)',
  TRV_RISK_LOADING = 'RISK LOADINGS (TRV)',
}

export enum EXCLUSIONS_TYPES {
  MED_RISK_EXCLUSIONS = 'EXCLUSIONS (MED)',
  MTR_RISK_EXCLUSIONS = 'EXCLUSIONS (MTR)',
  TRV_RISK_EXCLUSIONS = 'EXCLUSIONS (TRV)',
}
export enum NATURE_OF_LOSS {
  NORMAL_ACCIDENTS = 'Normal Accidents',
  RIOT_AND_STRIKE = 'Riot & Strike',
  HIT_AND_RUN = 'Hit & Run',
  FLOOD = 'Flood',
  THEFT = 'Theft',
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MODULES, PERMISSION_ACTIONS } from '@shared/constants';
import { getModulePermission } from '@shared/utils';

import { ModuleGuard } from './modules/core/guards/module.guard';
import { PermissionGuard } from './modules/core/guards/permission.guard';
import { IAMGuard } from './modules/setup/iam-config/guards/iam.guard';
import { UserManagementGuard } from './modules/setup/iam-config/guards/user-management.guard';
import {
  IAMUserManagement,
  USER_MGT_FEATURES,
} from './modules/setup/iam-config/services/iam-config.service';
import { StorageGuard } from './modules/setup/storage/guards/storage.guard';
import { AboutComponent } from './pages/about/about.component';
import { ViewReleaseNoteComponent } from './pages/about/popups/view-release-note/view-release-note.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DeactivateModuleComponent } from './pages/deactivate-module/deactivate-module.component';
import { HomeComponent } from './pages/home/home.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PersonalizationComponent } from './pages/personalization/personalization.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AppGuard } from './app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/launchpad',
    pathMatch: 'full',
  },
  {
    path: 'app',
    redirectTo: '/app/launchpad',
    pathMatch: 'full',
  },
  {
    path: 'app/auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'app/checkout',
    loadChildren: () =>
      import('./modules/aggregator/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
  },
  {
    path: 'app/preview',
    loadChildren: () =>
      import('./modules/preview/preview.module').then((m) => m.PreviewModule),
  },
  {
    path: 'app',
    component: DashboardComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: 'launchpad',
        component: HomeComponent,
        data: {
          title: 'app.launchpad',
          goBackEnabled: false,
          searchBarEnabled: false,
        },
      },
      {
        path: 'about',
        component: AboutComponent,
        data: {
          title: 'app.about',
          goBackEnabled: true,
        },
      },
      {
        path: 'view-release-note',
        component: ViewReleaseNoteComponent,
        data: {
          title: 'view-release-note',
          goBackEnabled: true,
        },
      },
      {
        path: 'personalization',
        component: PersonalizationComponent,
        data: {
          title: 'Personalization',
          goBackEnabled: true,
        },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'app.profile',
          goBackEnabled: true,
        },
      },
      {
        path: 'profile/reset-password',
        component: ResetPasswordComponent,
        canActivate: [UserManagementGuard],
        data: {
          title: 'app.reset-password',
          goBackEnabled: true,
          config: USER_MGT_FEATURES.SELF_PASSWORD_RESET,
          configCb: (data: IAMUserManagement) => {
            return data[USER_MGT_FEATURES.SELF_PASSWORD_RESET];
          },
        },
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./modules/connected-apps/connected-apps.module').then(
            (m) => m.ConnectedAppsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.APPS,
          allowedPermissions: getModulePermission(MODULES.APPS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'connections',
        loadChildren: () =>
          import('./modules/connections/connections.module').then(
            (m) => m.ConnectionsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.CONNECTIONS,
          allowedPermissions: getModulePermission(MODULES.CONNECTIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'authorization',
        loadChildren: () =>
          import('./modules/permissions/permissions.module').then(
            (m) => m.PermissionModule
          ),
      },
      {
        path: 'reference-categories',
        loadChildren: () =>
          import('./modules/references/references.module').then(
            (m) => m.ReferencesModule
          ),
      },
      {
        path: 'setup',
        loadChildren: () =>
          import('./modules/setup/setup.module').then((m) => m.SetupModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.SETUP,
          allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'identities',
        loadChildren: () =>
          import('./modules/identities/identities.module').then(
            (m) => m.IdentitiesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard, IAMGuard],
        data: {
          module: MODULES.IDENTITIES,
          allowedPermissions: getModulePermission(MODULES.IDENTITIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'knowledgebase',
        loadChildren: () =>
          import('./modules/knowledgebase/knowledgebase.module').then(
            (m) => m.KnowledgebaseModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.KNOWLEDGEBASE,
          allowedPermissions: getModulePermission(MODULES.KNOWLEDGEBASE, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'notices',
        loadChildren: () =>
          import('./modules/notices/notices.module').then(
            (m) => m.NoticesModule
          ),
        canActivate: [ModuleGuard],
        data: {
          module: MODULES.NOTICES,
        },
      },
      {
        path: 'logs',
        loadChildren: () =>
          import('./modules/logs/logs.module').then((m) => m.LogsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.LOGS,
          allowedPermissions: getModulePermission(MODULES.LOGS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./modules/messages/message.module').then(
            (m) => m.MessageModule
          ),
      },
      {
        path: 'storage',
        loadChildren: () =>
          import('./modules/storage/storage.module').then(
            (m) => m.StorageModule
          ),
        canLoad: [StorageGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.NOTIFICATIONS,
          allowedPermissions: getModulePermission(MODULES.NOTIFICATIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'modules',
        loadChildren: () =>
          import('./modules/modules/modules.module').then(
            (m) => m.ModulesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.MODULES,
          allowedPermissions: getModulePermission(MODULES.MODULES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      // BOMS
      {
        path: 'policies',
        loadChildren: () =>
          import('./modules/policies/policies.module').then(
            (m) => m.PoliciesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.POLICIES,
          allowedPermissions: getModulePermission(MODULES.POLICIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'reinsurers',
        loadChildren: () =>
          import('./modules/reinsurers/reinsurers.module').then(
            (m) => m.ReinsurersModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.REINSURERS,
          allowedPermissions: getModulePermission(MODULES.REINSURERS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      {
        path: 'partners',
        loadChildren: () =>
          import('./modules/partners/partners.module').then(
            (m) => m.PartnersModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PARTNERS,
          allowedPermissions: getModulePermission(MODULES.PARTNERS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'agreements',
        loadChildren: () =>
          import('./modules/agreements/agreements.module').then(
            (m) => m.AgreementsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.AGREEMENTS,
          allowedPermissions: getModulePermission(MODULES.AGREEMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'exchange-rates',
        loadChildren: () =>
          import('./modules/exchange-rates/exchange-rates.module').then(
            (m) => m.ExchangeRatesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.EXCHANGE_RATES,
          allowedPermissions: getModulePermission(MODULES.EXCHANGE_RATES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      {
        path: 'incidents',
        loadChildren: () =>
          import('./modules/incidents/incidents.module').then(
            (m) => m.IncidentsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.INCIDENTS,
          allowedPermissions: getModulePermission(MODULES.INCIDENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'workflows',
        loadChildren: () =>
          import('./modules/workflows/workflows.module').then(
            (m) => m.WorkflowsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.WORKFLOWS,
          allowedPermissions: getModulePermission(MODULES.WORKFLOWS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./modules/products/products.module').then(
            (m) => m.ProductsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PRODUCTS,
          allowedPermissions: getModulePermission(MODULES.PRODUCTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'chart-of-accounts',
        loadChildren: () =>
          import('./modules/chart-of-accounts/chart-of-accounts.module').then(
            (m) => m.ChartOfAccountsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CHART_OF_ACCOUNTS,
          allowedPermissions: getModulePermission(MODULES.CHART_OF_ACCOUNTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      // START: Accounting modules
      {
        path: 'customers',
        loadChildren: () =>
          import('./modules/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CUSTOMERS,
          allowedPermissions: getModulePermission(MODULES.CUSTOMERS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CUSTOMERS,
          allowedPermissions: getModulePermission(MODULES.REPORTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'entries',
        loadChildren: () =>
          import('./modules/entries/entries.module').then(
            (m) => m.EntriesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ENTRIES,
          allowedPermissions: getModulePermission(MODULES.ENTRIES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      //Finance
      {
        path: 'accounts',
        loadChildren: () =>
          import('./modules/accounts/accounts.module').then(
            (m) => m.AccountsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ACCOUNTS,
          allowedPermissions: getModulePermission(MODULES.ACCOUNTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./modules/invoices/invoices.module').then(
            (m) => m.InvoicesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.INVOICES,
          allowedPermissions: getModulePermission(MODULES.INVOICES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'receipts',
        loadChildren: () =>
          import('./modules/receipts/receipts.module').then(
            (m) => m.ReceiptsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.RECEIPTS,
          allowedPermissions: getModulePermission(MODULES.RECEIPTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./modules/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.PAYMENTS,
          allowedPermissions: getModulePermission(MODULES.PAYMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      // END: Accounting modules
      // START: Claims modules
      {
        path: 'claims',
        loadChildren: () =>
          import('./modules/claims/claims.module').then((m) => m.ClaimsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CLAIMS,
          allowedPermissions: getModulePermission(MODULES.CLAIMS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'assessors',
        loadChildren: () =>
          import('./modules/assessors/assessors.module').then(
            (m) => m.AssessorsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ASSESSORS,
          allowedPermissions: getModulePermission(MODULES.ASSESSORS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'assessments',
        loadChildren: () =>
          import('./modules/assessments/assessments.module').then(
            (m) => m.AssessmentsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ASSESSMENTS,
          allowedPermissions: getModulePermission(MODULES.ASSESSMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      // END: Claims modules
      {
        path: 'controls',
        loadChildren: () =>
          import('./modules/controls/controls.module').then(
            (m) => m.ControlsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.CONTROLS,
          allowedPermissions: getModulePermission(MODULES.CONTROLS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./modules/maps/maps.module').then((m) => m.MapsModule),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.MAPS,
          allowedPermissions: getModulePermission(MODULES.MAPS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'cover-notes',
        loadChildren: () =>
          import('./modules/cover-notes/cover-notes.module').then(
            (m) => m.CoverNotesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.COVER_NOTES,
          allowedPermissions: getModulePermission(MODULES.COVER_NOTES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'endorsements',
        loadChildren: () =>
          import('./modules/endorsements/endorsements.module').then(
            (m) => m.EndorsementsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.ENDORSEMENTS,
          allowedPermissions: getModulePermission(MODULES.ENDORSEMENTS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },

      // finance modules
      {
        path: 'branches',
        loadChildren: () =>
          import('./modules/branches/branches.module').then(
            (m) => m.BranchesModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.BRANCHES,
          allowedPermissions: getModulePermission(MODULES.BRANCHES, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'gateways',
        loadChildren: () =>
          import('./modules/aggregator/gateways/gateways.module').then(
            (m) => m.GatewaysModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.GATEWAYS,
          allowedPermissions: getModulePermission(MODULES.GATEWAYS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./modules/aggregator/transactions/transactions.module').then(
            (m) => m.TransactionsModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.TRANSACTIONS,
          allowedPermissions: getModulePermission(MODULES.TRANSACTIONS, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'blacklist',
        loadChildren: () =>
          import('./modules/blacklist/blacklist.module').then(
            (m) => m.BlacklistModule
          ),
        canLoad: [ModuleGuard, PermissionGuard],
        data: {
          module: MODULES.BLACKLIST,
          allowedPermissions: getModulePermission(MODULES.BLACKLIST, [
            PERMISSION_ACTIONS.READ,
          ]),
        },
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./modules/integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          ),
      },
      {
        path: 'deactivated',
        component: DeactivateModuleComponent,
        data: {
          goBackEnabled: true,
        },
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: {
          title: 'pages.access-denied.error-title',
          goBackEnabled: true,
        },
      },
      // {
      //   path: 'forms-builder',
      //   loadChildren: () =>
      //     import('./modules/form-builder/form-builder.module').then(
      //       (m) => m.FormBuilderModule
      //     ),
      // },
    ],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { ENDPOINTS } from '@shared/constants';

import { EndpointParams } from './modules/types';

const BASE_URLS = {
  AGREEMENTS: `/agreements`,
  ASSETS: {
    IMAGES: `/assets/images`,
  },
  AUTH: `/auth`,
  BRANCHES: `/branches`,
  BLACKLIST: `/blacklist`,
  PRODUCTS: `/products`,
  CONNECTED_APPS: `/connected-apps`,
  CONNECTIONS: `/connections`,
  IDENTITIES: `/identities`,
  INTEGRATIONS: {
    ENDPOINTS: `/integrations/endpoints`,
    MAPPING: `/integrations/mappings`,
  },
  JOB_QUEUE: `/job-queue`,
  MODULES: `/modules`,
  PERSONALIZATION: `/personalization`,
  PRIVILEGES: `/privileges`,
  NOTIFICATION: `/notifications`,
  CONFIGURABLE_FIELDS: {
    VALIDATIONS: `/configurable-fields/validations`,
  },
  REFERENCES: {
    REFERENCE_CATEGORIES: `/reference-categories`,
    REFERENCES: `/references`,
    IAM_REFERENCE_CATEGORIES: `/iam/reference-categories`,
    IAM_REFERENCES: `/iam/references`,
    EXTERNAL_REFERENCE_CATEGORIES: `/external/reference-categories`,
    EXTERNAL_REFERENCES: `/external/references`,
    CLIENT_REFERENCE_CATEGORIES: `/client/reference-categories`,
    CLIENT_REFERENCES: `/client/references`,
    TEMPLATES: `/templates`,
    FORMULA: `/formula`,
  },

  //ASSESSORS
  ASSESSORS: `/assessors`,

  //ASSESSMENTS
  ASSESSMENTS: `/assessments`,

  //ACCOUNTS
  ACCOUNTS: `/accounts`,

  //EXCHANGE-RATES
  EXCHANGE_RATES: '/exchange-rates',

  //CONTROLS
  CONTROLS: `/controls`,

  // CLAIMS
  CLAIMS: `/claims`,
  CLAIMS_ASSESS: `/claims-assessments`,
  CLAIMS_WORKBENCH: `/claims-workbench`,

  //COVER NOTES
  COVER_NOTES: `/cover-notes`,

  //CUSTOMERS
  CUSTOMERS: `/customers`,

  //DOUBLE ENTRY
  DOUBLE_ENTRY: `/double-entry`,

  // ENDORSEMENTS
  ENDORSEMENTS: `/endorsements`,

  //REINSURERS
  REINSURERS: `/reinsurers`,

  //CHART OF ACCOUNTS
  CHART_OF_ACCOUNTS: `/chart-of-accounts`,

  //ENTRIES
  ENTRIES: `/entries`,

  //INVOICES
  INVOICES: `/invoices`,

  //Reports
  REPORTS: `/reports`,

  SETUP: {
    AUTHORIZERS: `/setup/iam-config/authorizers`,
    IAM_HANDSHAKE: `/setup/iam-config/handshake`,
    BACKUPS: `/setup/backups`,
    BACKUP_SCHEDULES: `/setup/backup-schedules`,
    LANGUAGES: `/setup/languages`,
    REFERENCE_MAPPINGS: `/setup/reference-mappings`,
    CURRENCIES: `/setup/currencies`,
    EXTERNAL_SYSTEMS: `/setup/external-systems`,
    EXTERNAL_SYSTEMS_KEYS: `/setup/external-systems/keys`,
  },
  STORAGE: {
    INTERNAL: '/internal/storage',
    INTERNAL_ENTITY: '/internal/storage/entity',
    INTERNAL_SHARE_INTERNALLY: '/internal/storage/share/internal',
  },

  //PARTNERS
  PARTNERS: `/partners`,

  //PAYMENTS
  PAYMENTS: `/payments`,

  //BUSINESS
  POLICIES: `/policies`,

  //MAPS
  MAPS: `/maps`,

  // GATEWAYS
  GATEWAYS: `/gateways`,

  // CHECKOUTS
  CHECKOUT: `/checkout`,

  // TRANSACTIONS
  PUBLIC_TRANSACTIONS: `/transactions`,
  TRANSACTIONS: `/transaction`,

  //RECEIPTS
  RECEIPTS: `/receipts`,

  //INCIDENTS
  INCIDENTS: `/incidents`,

  //WORKFLOWS
  WORKFLOWS: `/workflows`,
};

/**
 *
 * NOTE: if you get an error here that might be due to missing cases.
 * Check whether you have added all the ENDPOINTS to cases in below switch or not
 *
 */
const getURLPath = (endpoint: EndpointParams): string => {
  switch (endpoint.endpoint) {
    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // ACCOUNTS
    case ENDPOINTS.ACCOUNTS_GET_ALL:
    case ENDPOINTS.ACCOUNTS_ADD_NEW:
      return BASE_URLS.ACCOUNTS;
    case ENDPOINTS.ACCOUNTS_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.ACCOUNTS}/all/partial`;
    case ENDPOINTS.ACCOUNTS_GET_ONE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}`;
    case ENDPOINTS.ACCOUNTS_UPDATE_ONE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}`;
    case ENDPOINTS.ACCOUNTS_DELETE_ONE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}`;
    case ENDPOINTS.ACCOUNTS_RESTORE_ONE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}`;
    case ENDPOINTS.ACCOUNTS_DISABLE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}/disable`;
    case ENDPOINTS.ACCOUNTS_ENABLE:
      return `${BASE_URLS.ACCOUNTS}/${endpoint.params.id}/enable`;

    //AGREEMENTS
    case ENDPOINTS.AGREEMENTS_ADD_NEW:
    case ENDPOINTS.AGREEMENTS_GET_ALL:
      return BASE_URLS.AGREEMENTS;

    case ENDPOINTS.AGREEMENTS_DELETE:
    case ENDPOINTS.AGREEMENTS_UPDATE:
    case ENDPOINTS.AGREEMENTS_GET_ONE:
      return `${BASE_URLS.AGREEMENTS}/${endpoint.params.id}`;

    case ENDPOINTS.AGREEMENTS_RESTORE:
      return `${BASE_URLS.AGREEMENTS}/${endpoint.params.id}/restore`;

    // AUTH
    case ENDPOINTS.AUTH_REFRESH_TOKEN:
      return `${BASE_URLS.AUTH}/token`;

    case ENDPOINTS.AUTH_LOGIN:
      return `${BASE_URLS.AUTH}/login`;

    case ENDPOINTS.AUTH_PROFILE_IMAGE_UPLOAD:
      return `${BASE_URLS.AUTH}/upload/profile-image`;

    case ENDPOINTS.AUTH_LOGIN_IMPERSONATE:
      return `${BASE_URLS.AUTH}/impersonate/login`;

    case ENDPOINTS.AUTH_CHECK_USER:
      return `${BASE_URLS.AUTH}/check`;

    case ENDPOINTS.AUTH_LOGOUT:
      return `${BASE_URLS.AUTH}/logout`;

    case ENDPOINTS.AUTH_FORGOT_PASSWORD:
      return `${BASE_URLS.AUTH}/password/forgot`;

    case ENDPOINTS.AUTH_VALIDATE_TOKEN:
      return `${BASE_URLS.AUTH}/token/validate`;

    case ENDPOINTS.AUTH_RESET_PASSWORD:
      return `${BASE_URLS.AUTH}/password/reset`;

    case ENDPOINTS.AUTH_REGISTER:
      return `${BASE_URLS.AUTH}/register`;

    case ENDPOINTS.AUTH_VERIFY:
      return `${BASE_URLS.AUTH}/verify`;

    case ENDPOINTS.AUTH_PROFILE:
      return `${BASE_URLS.AUTH}/profile`;

    case ENDPOINTS.AUTH_SEND_OTP:
      return `${BASE_URLS.AUTH}/otp/send`;

    //ASSESSORS
    case ENDPOINTS.ASSESSORS_ADD_NEW:
    case ENDPOINTS.ASSESSORS_GET_ALL:
      return BASE_URLS.ASSESSORS;

    case ENDPOINTS.ASSESSORS_GET_ALL_MAP:
      return `${BASE_URLS.ASSESSORS}/assessors`;
    case ENDPOINTS.ASSESSORS_GET_ONE_IDENTITY:
      return `${BASE_URLS.ASSESSORS}/${endpoint.params.id}/identity`;
    case ENDPOINTS.ASSESSORS_GET_ONE:
      return `${BASE_URLS.ASSESSORS}/${endpoint.params.id}`;
    case ENDPOINTS.ASSESSORS_UPDATE_ONE:
      return `${BASE_URLS.ASSESSORS}/${endpoint.params.id}`;
    case ENDPOINTS.ASSESSORS_UPDATE_STATUS:
      return `${BASE_URLS.ASSESSORS}/${endpoint.params.id}/status`;

    //ASSESSMENTS
    case ENDPOINTS.ASSESSMENTS_GET_ALL:
      return BASE_URLS.ASSESSMENTS;

    // BLACKLIST
    case ENDPOINTS.BLACKLIST_ADD_NEW:
    case ENDPOINTS.BLACKLIST_GET_ALL:
      return BASE_URLS.BLACKLIST;
    case ENDPOINTS.BLACKLIST_GET_ONE:
    case ENDPOINTS.BLACKLIST_UPDATE_ONE:
      return `${BASE_URLS.BLACKLIST}/${endpoint.params.id}`;
    case ENDPOINTS.BLACKLIST_DELETE_ONE:
      return `${BASE_URLS.BLACKLIST}/${endpoint.params.id}/delete`;
    case ENDPOINTS.BLACKLIST_RESTORE_ONE:
      return `${BASE_URLS.BLACKLIST}/${endpoint.params.id}/restore`;
    case ENDPOINTS.BLACKLIST_GET_ALL_DOCUMENTATIONS:
      return `${BASE_URLS.BLACKLIST}/docs`;
    case ENDPOINTS.BLACKLIST_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.BLACKLIST}/keys/generate`;
    case ENDPOINTS.BLACKLIST_SAVE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.BLACKLIST}/keys`;
    case ENDPOINTS.BLACKLIST_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.BLACKLIST}/keys/${endpoint.params.id}`;
    case ENDPOINTS.BLACKLIST_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.BLACKLIST}/keys/getAll`;
    case ENDPOINTS.BLACKLIST_DELETE_KEY:
      return `${BASE_URLS.BLACKLIST}/keys/${endpoint.params.id}`;
    case ENDPOINTS.BLACKLIST_RESTORE_KEY:
      return `${BASE_URLS.BLACKLIST}/keys/${endpoint.params.id}/restore`;
    case ENDPOINTS.BLACKLIST_GENERATE_SECRETS:
      return `${BASE_URLS.BLACKLIST}/generate-secrets`;

    // BRANCHES
    case ENDPOINTS.BRANCHES_ADD_NEW:
    case ENDPOINTS.BRANCHES_GET_ALL:
      return BASE_URLS.BRANCHES;
    case ENDPOINTS.BRANCHES_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.BRANCHES}/all/partial`;
    case ENDPOINTS.BRANCHES_DELETE_ONE:
    case ENDPOINTS.BRANCHES_UPDATE_ONE:
    case ENDPOINTS.BRANCHES_GET_ONE:
      return `${BASE_URLS.BRANCHES}/${endpoint.params.id}`;

    case ENDPOINTS.BRANCHES_RESTORE_ONE:
      return `${BASE_URLS.BRANCHES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.BRANCHES_DISABLE:
      return `${BASE_URLS.BRANCHES}/${endpoint.params.id}/disable`;

    case ENDPOINTS.BRANCHES_ENABLE:
      return `${BASE_URLS.BRANCHES}/${endpoint.params.id}/enable`;

    case ENDPOINTS.BRANCHES_VIEW_HIERARCHY:
      return `${BASE_URLS.BRANCHES}/hierarchy`;

    //CHART OF ACCOUNTS
    case ENDPOINTS.CHART_OF_ACCOUNT_ADD_NEW:
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ALL:
      return BASE_URLS.CHART_OF_ACCOUNTS;
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/all/partial`;
    case ENDPOINTS.CHART_OF_ACCOUNT_DELETE_ONE:
    case ENDPOINTS.CHART_OF_ACCOUNT_UPDATE_ONE:
    case ENDPOINTS.CHART_OF_ACCOUNT_GET_ONE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}`;

    case ENDPOINTS.CHART_OF_ACCOUNT_RESTORE_ONE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/restore`;

    case ENDPOINTS.CHART_OF_ACCOUNT_DISABLE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/disable`;

    case ENDPOINTS.CHART_OF_ACCOUNT_ENABLE:
      return `${BASE_URLS.CHART_OF_ACCOUNTS}/${endpoint.params.id}/enable`;

    // CONTROLS
    case ENDPOINTS.CONTROLS_GET_ALL:
    case ENDPOINTS.CONTROLS_ADD_NEW:
      return BASE_URLS.CONTROLS;
    case ENDPOINTS.CONTROLS_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.CONTROLS}/all/partial`;
    case ENDPOINTS.CONTROLS_GET_ONE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}`;
    case ENDPOINTS.CONTROLS_UPDATE_ONE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}`;
    case ENDPOINTS.CONTROLS_DELETE_ONE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}`;
    case ENDPOINTS.CONTROLS_RESTORE_ONE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}`;
    case ENDPOINTS.CONTROLS_DISABLE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}/disable`;
    case ENDPOINTS.CONTROLS_ENABLE:
      return `${BASE_URLS.CONTROLS}/${endpoint.params.id}/enable`;

    // ENTRIES
    case ENDPOINTS.ENTRIES_ADD_NEW:
    case ENDPOINTS.ENTRIES_GET_ALL:
      return BASE_URLS.ENTRIES;

    // INCIDENTS
    case ENDPOINTS.INCIDENTS_ADD_NEW:
    case ENDPOINTS.INCIDENTS_GET_ALL:
      return `${BASE_URLS.INCIDENTS}`;

    case ENDPOINTS.INCIDENTS_DELETE_ONE:
    case ENDPOINTS.INCIDENTS_UPDATE_ONE:
    case ENDPOINTS.INCIDENTS_GET_ONE:
      return `${BASE_URLS.INCIDENTS}/${endpoint.params.id}`;

    case ENDPOINTS.INCIDENTS_RESTORE_ONE:
      return `${BASE_URLS.INCIDENTS}/${endpoint.params.id}/restore`;
    case ENDPOINTS.AUTH_USERNAME:
      return `${BASE_URLS.AUTH}/username`;

    case ENDPOINTS.AUTH_CODE:
      return `${BASE_URLS.AUTH}/code`;

    // CONNECTED - APPS
    case ENDPOINTS.CONNECTED_APPS_CONNECT_NEW_APPS:
    case ENDPOINTS.CONNECTED_APPS_GET_ALL_APPS:
      return BASE_URLS.CONNECTED_APPS;

    case ENDPOINTS.CONNECTED_APPS_GET_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_UPDATE_ONE_APP:
    case ENDPOINTS.CONNECTED_APPS_DELETE_ONE_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/fields`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/roles`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/permissions`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/docs`;

    case ENDPOINTS.CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/${endpoint.params.status_change}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/reference-categories/${endpoint.params.categoryId}/references`;

    case ENDPOINTS.CONNECTED_APPS_HANDSHAKE:
      return `${BASE_URLS.CONNECTED_APPS}/handshake`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_SECRETS:
      return `${BASE_URLS.CONNECTED_APPS}/generate-secrets`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate`;

    case ENDPOINTS.CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/generate-client-credentials`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-client-secret`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys/save-deleted-api-key`;

    case ENDPOINTS.CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.CONNECTED_APPS_DELETE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.CONNECTED_APPS_RESTORE_KEY:
      return `${BASE_URLS.CONNECTED_APPS}/${endpoint.params.appId}/keys/${endpoint.params.id}/restore`;

    //CONNECTIONS
    case ENDPOINTS.CONNECTIONS_GET_ALL:
      return BASE_URLS.CONNECTIONS;

    case ENDPOINTS.CONNECTIONS_UPDATE:
      return `${BASE_URLS.CONNECTIONS}/${endpoint.params.id}`;

    //CLAIMS
    case ENDPOINTS.CLAIMS_ADD_NEW:
      return BASE_URLS.CLAIMS;
    case ENDPOINTS.CLAIMS_ADD_NEW_MEDICAL_BULK:
      return `${BASE_URLS.CLAIMS}/medical-bulk`;
    case ENDPOINTS.CLAIMS_GET_ALL:
      return BASE_URLS.CLAIMS;
    case ENDPOINTS.CLAIMS_GET_ONE:
    case ENDPOINTS.CLAIMS_DELETE_ONE:
    case ENDPOINTS.CLAIMS_UPDATE_ONE:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}`;
    case ENDPOINTS.CLAIMS_RESTORE_ONE:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/restore`;

    //DOUBLE ENTRY
    case ENDPOINTS.ADD_DOUBLE_ENTRIES:
    case ENDPOINTS.GET_DOUBLE_ENTRIES:
      return BASE_URLS.DOUBLE_ENTRY;
    case ENDPOINTS.UPDATE_DOUBLE_ENTRIES:
      return `${BASE_URLS.DOUBLE_ENTRY}/${endpoint.params.id}`;

    case ENDPOINTS.CLAIMS_GET_ALL_REPORTS:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-get-reports`;
    case ENDPOINTS.CLAIMS_ADD_REPORT:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-add-report`;
    case ENDPOINTS.CLAIMS_UPDATE_REPORT:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-update-report`;
    case ENDPOINTS.CLAIMS_DELETE_REPORT:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-delete-report`;

    case ENDPOINTS.CLAIMS_GET_ALL_DOCUMENTS:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-get-documents`;
    case ENDPOINTS.CLAIMS_ADD_DOCUMENT:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-add-document`;
    case ENDPOINTS.CLAIMS_DELETE_DOCUMENT:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/claim-delete-document`;

    case ENDPOINTS.CLAIMS_GET_PREVIOUS_CLAIMS:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/previous-claims`;

    case ENDPOINTS.CLAIMS_GET_ALL_SALVAGES:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/get-salvages`;
    case ENDPOINTS.CLAIMS_ADD_SALVAGES_ITEM:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-salvage`;
    case ENDPOINTS.CLAIMS_UPDATE_SALVAGE:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/update-salvage`;
    case ENDPOINTS.CLAIMS_DELETE_SALVAGE:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/delete-salvage`;

    case ENDPOINTS.CLAIMS_GET_ALL_DECISIONS:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/handler-decisions`;
    case ENDPOINTS.CLAIMS_ADD_DECISION:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/handler-decision`;

    case ENDPOINTS.CLAIMS_GET_SUPERVISOR_DECISION:
    case ENDPOINTS.CLAIMS_ADD_SUPERVISOR_DECISION:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/supervisor-decision`;

    case ENDPOINTS.CLAIMS_RAISE_APPEAL:
      return `${BASE_URLS.CLAIMS}/${endpoint.params.id}/raise-appeal`;

    case ENDPOINTS.CLAIMS_GET_ALL_FEED_BACKS:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/all-feedbacks`;
    case ENDPOINTS.CLAIMS_ADD_FEED_BACK:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-feedback`;
    case ENDPOINTS.CLAIMS_UPDATE_FEED_BACK:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/update-feedback`;
    case ENDPOINTS.CLAIMS_DELETE_FEED_BACK:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/delete-feedback`;

    case ENDPOINTS.CLAIMS_GET_PAYMENT_SUGGESTIONS:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/payment-suggestions`;
    case ENDPOINTS.CLAIMS_ADD_PAYMENT_SUGGESTIONS:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-payment-suggestion`;
    case ENDPOINTS.CLAIMS_GET_PAYMENT_OPTION:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/payment-option`;
    case ENDPOINTS.CLAIMS_ADD_PAYMENT_OPTION:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-payment-option`;
    case ENDPOINTS.CLAIMS_GET_PAYMENT_APPROVAL:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/payment-approval`;
    case ENDPOINTS.CLAIMS_ADD_PAYMENT_APPROVAL:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-payment-approval`;
    case ENDPOINTS.CLAIMS_GET_ALL_CONFIRMATIONS:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/confirmations`;
    case ENDPOINTS.CLAIMS_ADD_CONFIRMATION:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/add-confirmation`;
    case ENDPOINTS.CLAIMS_UPDATE_CONFIRMATION:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/update-confirmation`;
    case ENDPOINTS.CLAIMS_DELETE_CONFIRMATION:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/delete-confirmation`;

    case ENDPOINTS.CLAIMS_GET_ALL_ASSESS:
      return `${BASE_URLS.CLAIMS_ASSESS}`;
    case ENDPOINTS.CLAIMS_GET_ALL_ASSESS_FOR_SELECTED_CLAIM:
      return `${BASE_URLS.CLAIMS_ASSESS}/${endpoint.params.id}/claim`;
    case ENDPOINTS.CLAIMS_ADD_ASSESS:
      return `${BASE_URLS.CLAIMS_ASSESS}/${endpoint.params.id}/add-assessment`;
    case ENDPOINTS.CLAIMS_UPDATE_ASSESS:
      return `${BASE_URLS.CLAIMS_ASSESS}/${endpoint.params.id}/update-assessment`;
    case ENDPOINTS.CLAIMS_DELETE_ASSESS:
      return `${BASE_URLS.CLAIMS_ASSESS}/${endpoint.params.id}/delete-assess`;

    case ENDPOINTS.CLAIMS_GET_ALL_ACTIVITY_HISTORY:
      return `${BASE_URLS.CLAIMS_WORKBENCH}/${endpoint.params.id}/activity-history`;

    // ENDORSEMENTS
    case ENDPOINTS.ENDORSEMENTS_GET_ALL:
    case ENDPOINTS.ENDORSEMENTS_ADD_NEW:
      return BASE_URLS.ENDORSEMENTS;
    case ENDPOINTS.ENDORSEMENT_CLAUSE_GET_RECENT:
      return `${BASE_URLS.ENDORSEMENTS}/clause`;
    case ENDPOINTS.ENDORSEMENT_GET_ONE:
    case ENDPOINTS.ENDORSEMENT_DELETE_ONE:
    case ENDPOINTS.ENDORSEMENT_UPDATE_ONE:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}`;
    case ENDPOINTS.ENDORSEMENT_RESTORE_ONE:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/restore`;
    // endorsement evaluation authorizer
    case ENDPOINTS.ENDORSEMENTS_ASSIGN_AUTHORIZER:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/assign-authorizer`;
    case ENDPOINTS.ENDORSEMENTS_GET_ALL_AUTHORIZERS:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/get-authorizers`;
    case ENDPOINTS.ENDORSEMENTS_ADD_AUTHORIZATION_COMMENTS:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/authorization-comment`;
    case ENDPOINTS.ENDORSEMENTS_GET_AUTHORIZATION_COMMENTS:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/authorization-comments`;
    case ENDPOINTS.ENDORSEMENTS_ADD_INCLUSION_COMMENTS:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/inclusion-comment`;
    case ENDPOINTS.ENDORSEMENTS_GET_INCLUSION_COMMENTS:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/inclusion-comments`;
    case ENDPOINTS.ENDORSEMENT_GET_SCHEDULE_TEMPLATE:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/schedule-template`;
    case ENDPOINTS.ENDORSEMENT_GET_INCLUSION_SCHEDULE_TEMPLATE:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/inclusion-schedule-template`;
    case ENDPOINTS.ENDORSEMENT_SET_APPROVED_PREMIUM:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/update-premium`;
    case ENDPOINTS.ENDORSEMENT_PUBLISH:
      return `${BASE_URLS.ENDORSEMENTS}/${endpoint.params.id}/publish`;

    //EXCHANGE_RATES
    case ENDPOINTS.EXCHANGE_RATES_GET_ALL:
      return BASE_URLS.EXCHANGE_RATES;
    case ENDPOINTS.EXCHANGE_RATES_ADD_NEW:
      return BASE_URLS.EXCHANGE_RATES;
    case ENDPOINTS.EXCHANGE_RATES_UPDATE:
      return `${BASE_URLS.EXCHANGE_RATES}/${endpoint.params.id}`;
    case ENDPOINTS.EXCHANGE_RATES_GET_ONE:
      return `${BASE_URLS.EXCHANGE_RATES}/${endpoint.params.id}`;
    case ENDPOINTS.EXCHANGE_RATES_ADD_NEW_BULK:
      return `${BASE_URLS.EXCHANGE_RATES}/exchange-rates-bulk`;
    case ENDPOINTS.EXCHANGE_RATES_BULK_UPDATE:
      return `${BASE_URLS.EXCHANGE_RATES}/exchange-rates-bulk-update`;
    case ENDPOINTS.EXCHANGE_RATES_DELETE_ONE:
      return `${BASE_URLS.EXCHANGE_RATES}/${endpoint.params.id}`;
    case ENDPOINTS.EXCHANGE_RATES_RESTORE_ONE:
      return `${BASE_URLS.EXCHANGE_RATES}/${endpoint.params.id}`;

    // IDENTITIES
    case ENDPOINTS.IDENTITIES_GET_ALL:
      return `${BASE_URLS.IDENTITIES}`;

    case ENDPOINTS.IDENTITIES_SUBORDINATES:
      return `${BASE_URLS.IDENTITIES}/subordinates`;

    case ENDPOINTS.IDENTITIES_SUBORDINATE_IDS:
      return `${BASE_URLS.IDENTITIES}/subordinate-ids`;

    case ENDPOINTS.IDENTITIES_HIERARCHICAL:
      return `${BASE_URLS.IDENTITIES}/hierarchy`;

    case ENDPOINTS.IDENTITIES_GET_INTERNAL_FIELD:
      return `${BASE_URLS.IDENTITIES}/get-field-data`;

    case ENDPOINTS.IDENTITIES_GET_ONE:
      return `${BASE_URLS.IDENTITIES}/${endpoint.params.id}`;

    // INVOICES
    case ENDPOINTS.INVOICES_GET_ALL:
      return BASE_URLS.INVOICES;
    case ENDPOINTS.INVOICES_SHARE_INVOICE:
      return `${BASE_URLS.INVOICES}/share-invoice`;
    case ENDPOINTS.INVOICES_SHARE_PAYABLE_LINK:
      return `${BASE_URLS.INVOICES}/share-payable-link`;
    case ENDPOINTS.INVOICE_GET_BY_PARAMS:
      return `${BASE_URLS.INVOICES}/invoiceByParams`;
    case ENDPOINTS.INVOICES_HASH_PAYMENT_DETAILS:
      return `${BASE_URLS.INVOICES}/hash`;

    //Reports
    case ENDPOINTS.DEBTORS_OUTSTANDING_REPORTS_GET_ALL:
      return `${BASE_URLS.REPORTS}/debtors-outstanding`;
    case ENDPOINTS.CUSTOMER_REPORTS_GET_ALL:
      return `${BASE_URLS.REPORTS}/statement-of-account`;
    case ENDPOINTS.CUSTOMER_REPORTS_GET_ALL_BY_ACCOUNT:
      return `${BASE_URLS.REPORTS}/statement-of-account-by-invoice`;
    case ENDPOINTS.REPORT_STATEMENT:
      return `${BASE_URLS.REPORTS}/statement`;
    // INTEGRATION - ENDPOINTS

    case ENDPOINTS.INTEGRATION_ENDPOINTS_GET_ALL:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_ADD_ONE:
      return BASE_URLS.INTEGRATIONS.ENDPOINTS;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_ENDPOINTS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_ENDPOINTS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.ENDPOINTS}/${endpoint.params.id}/verify`;

    // INTEGRATION -MAPPINGS
    case ENDPOINTS.INTEGRATION_MAPPINGS_ADD_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_GET_ALL:
      return BASE_URLS.INTEGRATIONS.MAPPING;
    case ENDPOINTS.INTEGRATION_MAPPINGS_UPDATE_ONE:
    case ENDPOINTS.INTEGRATION_MAPPINGS_DELETE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_RESTORE_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/restore`;
    case ENDPOINTS.INTEGRATION_MAPPINGS_VERIFY_ONE:
      return `${BASE_URLS.INTEGRATIONS.MAPPING}/${endpoint.params.id}/verify`;

    // JOB_QUEUE
    case ENDPOINTS.JOB_QUEUE_CHECK_STATUS:
      return `${BASE_URLS.JOB_QUEUE}/${endpoint.params.id}`;

    // MODULES
    case ENDPOINTS.MODULES_GET_ALL:
      return BASE_URLS.MODULES;

    case ENDPOINTS.MODULES_GET_ONE_BY_ID:
    case ENDPOINTS.MODULES_UPDATE:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}`;

    case ENDPOINTS.MODULES_RESET:
      return `${BASE_URLS.MODULES}/${endpoint.params.id}/reset`;

    // PERSONALIZATION
    case ENDPOINTS.PERSONALIZATION_GET:
    case ENDPOINTS.PERSONALIZATION_UPDATE:
      return BASE_URLS.PERSONALIZATION;

    // PRIVILEGES
    case ENDPOINTS.PRIVILEGES_CREATE_ONE:
    case ENDPOINTS.PRIVILEGES_GET_ALL:
      return BASE_URLS.PRIVILEGES;

    case ENDPOINTS.PRIVILEGES_GET_ONE_BY_ID:
    case ENDPOINTS.PRIVILEGES_UPDATE:
    case ENDPOINTS.PRIVILEGES_DELETE:
      return `${BASE_URLS.PRIVILEGES}/${endpoint.params.id}`;

    case ENDPOINTS.PRIVILEGES_RESTORE:
      return `${BASE_URLS.PRIVILEGES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.PRIVILEGES_GET_RELEVANT:
      return `${BASE_URLS.PRIVILEGES}/relevant`;

    // PAYMENTS
    case ENDPOINTS.PAYMENTS_GET_ALL:
      return BASE_URLS.PAYMENTS;
    case ENDPOINTS.PAYMENTS_CREATE_PAYMENT:
      return BASE_URLS.PAYMENTS;
    case ENDPOINTS.PAYMENTS_GET_ONE:
      return `${BASE_URLS.PAYMENTS}/${endpoint.params.id}`;
    case ENDPOINTS.PAYMENTS_UPDATE_PAYMENT:
      return `${BASE_URLS.PAYMENTS}/${endpoint.params.id}`;
    case ENDPOINTS.PAYMENTS_UPDATE_STATUS:
      return `${BASE_URLS.PAYMENTS}/${endpoint.params.id}/status`;
    case ENDPOINTS.PAYMENTS_GENERATE_VOUCHER_NUMBER_AND_BATCH_ID:
      return `${BASE_URLS.PAYMENTS}/process-payments`;

    // NOTIFICATION
    case ENDPOINTS.NOTIFICATION_CERATE_WITHOUT_USER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_CERATE_WITH_USER:
      return `${BASE_URLS.NOTIFICATION}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_GET:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}`;

    case ENDPOINTS.NOTIFICATION_GET_USER_NOTIFICATIONS:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/user-notifications`;

    case ENDPOINTS.NOTIFICATION_FILTER:
      return BASE_URLS.NOTIFICATION;

    case ENDPOINTS.NOTIFICATIONS_MARK_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/${endpoint.params.id}/read`;

    case ENDPOINTS.NOTIFICATIONS_MARK_ALL_AS_READ:
      return `${BASE_URLS.NOTIFICATION}/mark-all-read`;

    // CONFIGURABLE_FIELDS - VALIDATIONS
    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_NAME:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/name`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/phone-number`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/address`;

    case ENDPOINTS.CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL:
      return `${BASE_URLS.CONFIGURABLE_FIELDS.VALIDATIONS}/email`;

    //PRODUCTS
    case ENDPOINTS.PRODUCTS_ADD_NEW:
    case ENDPOINTS.PRODUCTS_GET_ALL:
      return BASE_URLS.PRODUCTS;
    case ENDPOINTS.PRODUCTS_GET_ALL_MORE_DATA:
      return `${BASE_URLS.PRODUCTS}/more-data`;

    case ENDPOINTS.PRODUCTS_DELETE:
    case ENDPOINTS.PRODUCTS_UPDATE:
    case ENDPOINTS.PRODUCTS_GET_ONE:
      return `${BASE_URLS.PRODUCTS}/${endpoint.params.id}`;
    case ENDPOINTS.PRODUCTS_GET_ALL_CODES:
      return `${BASE_URLS.PRODUCTS}/codes`;

    case ENDPOINTS.PRODUCTS_RESTORE:
      return `${BASE_URLS.PRODUCTS}/${endpoint.params.id}/restore`;
    // REFERENCES - PREDEFINED_REFERENCES
    case ENDPOINTS.PREDEFINED_REFERENCES_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/predefined-value`;

    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.REFERENCE_CATEGORIES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_ADD_NEW:
      return BASE_URLS.REFERENCES.REFERENCE_CATEGORIES;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_SINGLE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/external/delete/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_UPDATE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}`;

    case ENDPOINTS.REFERENCE_CATEGORIES_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.REFERENCE_CATEGORIES_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_ADD_NEW:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2:
      return `/v2${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;
    // REFERENCES - REFERENCES
    case ENDPOINTS.REFERENCE_EDIT:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}`;

    case ENDPOINTS.REFERENCE_DELETE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/delete`;

    case ENDPOINTS.REFERENCE_RESTORE:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.categoryId}/references/${endpoint.params.referenceId}/restore`;

    case ENDPOINTS.REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;

    // REFERENCES - BULK ADD
    case ENDPOINTS.REFERENCE_CATEGORIES_REFERENCES_BULK_ADD:
      return `${BASE_URLS.REFERENCES.REFERENCE_CATEGORIES}/${endpoint.params.id}/references/bulk`;

    // REFERENCES - TEMPLATES
    case ENDPOINTS.TEMPLATES_ADD_NEW:
      return BASE_URLS.REFERENCES.TEMPLATES;

    case ENDPOINTS.TEMPLATES_UPDATE_ONE:
    case ENDPOINTS.TEMPLATES_GET_ONE:
      return `${BASE_URLS.REFERENCES.TEMPLATES}/${endpoint.params.id}`;

    // REFERENCES - FORMULA
    case ENDPOINTS.FORMULA_ANALYZE:
      return `${BASE_URLS.REFERENCES.FORMULA}/analyze`;

    case ENDPOINTS.FORMULA_EVALUATE:
      return `${BASE_URLS.REFERENCES.FORMULA}/evaluate`;

    // RECEIPTS
    case ENDPOINTS.RECEIPTS_ADD_NEW:
    case ENDPOINTS.RECEIPTS_GET_ALL:
      return `${BASE_URLS.RECEIPTS}`;
    case ENDPOINTS.RECEIPT_SHARE_RECEIPT:
      return `${BASE_URLS.RECEIPTS}/share-receipt`;
    case ENDPOINTS.RECEIPTS_AGGREGATOR_NEW:
      return `${BASE_URLS.RECEIPTS}/aggregator/${endpoint.params.separateReceipts}`;
    case ENDPOINTS.RECEIPTS_GET_REQUEST_CANCELLATION_DATA:
      return `${BASE_URLS.RECEIPTS}/getAllRequestCancellationData`;
    case ENDPOINTS.RECEIPTS_GET_ALL_EXCESS_DATA:
      return `${BASE_URLS.RECEIPTS}/getAllExcessData`;
    case ENDPOINTS.RECEIPTS_GET_ONE:
    case ENDPOINTS.RECEIPTS_DELETE_ONE:
    case ENDPOINTS.RECEIPTS_UPDATE_ONE:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}`;
    case ENDPOINTS.RECEIPTS_ADD_CANCEL_REQUEST:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}/requestCancellation`;
    case ENDPOINTS.RECEIPTS_ADD_CANCEL_APPROVAL:
      return `${BASE_URLS.RECEIPTS}/${endpoint.params.id}/approveCancellation`;

    // STORAGE - INTERNAL
    case ENDPOINTS.STORAGE_INTERNAL_UPLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL}/upload`;

    case ENDPOINTS.STORAGE_INTERNAL_DELETE:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}`;

    case ENDPOINTS.STORAGE_INTERNAL_PREVIEW:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/preview`;

    case ENDPOINTS.STORAGE_INTERNAL_DOWNLOAD:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/download`;

    case ENDPOINTS.STORAGE_INTERNAL_SHARE_INTERNALLY:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/share/internal`;

    case ENDPOINTS.STORAGE_INTERNAL_CONTENT:
      return `${BASE_URLS.STORAGE.INTERNAL_ENTITY}/${endpoint.params.entityId}/content`;

    // SETUP - REFERENCE_MAPPING
    case ENDPOINTS.SETUP_GET_ALL_REFERENCE_MAPPINGS:
      return BASE_URLS.SETUP.REFERENCE_MAPPINGS;

    case ENDPOINTS.SETUP_GET_REFERENCE_MAPPING_BY_KEY:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.key}`;

    case ENDPOINTS.SETUP_UPDATE_REFERENCE_MAPPING:
      return `${BASE_URLS.SETUP.REFERENCE_MAPPINGS}/${endpoint.params.id}`;

    // SETUP - BACKUP_SCHEDULES
    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ADD_NEW:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_GET_ALL:
      return BASE_URLS.SETUP.BACKUP_SCHEDULES;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE:
      return `${BASE_URLS.SETUP.BACKUP_SCHEDULES}/${endpoint.params.id}/${endpoint.params.status}`;

    // SETUP - IAM
    case ENDPOINTS.SETUP_IAM_AUTHORIZERS_AZURE:
      return `${BASE_URLS.SETUP.AUTHORIZERS}/azure`;

    case ENDPOINTS.SETUP_IAM_HANDSHAKE:
      return `${BASE_URLS.SETUP.IAM_HANDSHAKE}`;

    // SETUP - BACKUPS
    case ENDPOINTS.SETUP_BACKUPS_BACKUP_NOW:
    case ENDPOINTS.SETUP_BACKUPS_GET_ALL:
      return BASE_URLS.SETUP.BACKUPS;

    case ENDPOINTS.SETUP_BACKUPS_DOWNLOAD_ONE:
    case ENDPOINTS.SETUP_BACKUPS_DELETE_ONE:
      return `${BASE_URLS.SETUP.BACKUPS}/${endpoint.params.name}`;

    // SETUP - LANGUAGES
    case ENDPOINTS.SETUP_LANGUAGES_ADD_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_GET_ALL:
      return BASE_URLS.SETUP.LANGUAGES;

    case ENDPOINTS.SETUP_LANGUAGES_GET_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_UPDATE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_ENABLE_DISABLE_ONE:
    case ENDPOINTS.SETUP_LANGUAGES_DELETE_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_LANGUAGES_REFRESH_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/refresh`;

    case ENDPOINTS.SETUP_LANGUAGES_GET_CONTENT_OF_ONE:
      return `${BASE_URLS.SETUP.LANGUAGES}/${endpoint.params.id}/content`;

    // SETUP - CURRENCIES
    case ENDPOINTS.SETUP_CURRENCIES_ADD_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_GET_ALL:
      return BASE_URLS.SETUP.CURRENCIES;

    case ENDPOINTS.SETUP_CURRENCIES_GET_ONE:
    case ENDPOINTS.SETUP_CURRENCIES_UPDATE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_CURRENCIES_DELETE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/delete`;

    case ENDPOINTS.SETUP_CURRENCIES_RESTORE_ONE:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/restore`;

    case ENDPOINTS.SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY:
      return `${BASE_URLS.SETUP.CURRENCIES}/${endpoint.params.id}/base_currency`;

    // ASSETS
    case ENDPOINTS.ASSETS_IMAGES_UPLOAD:
      return BASE_URLS.ASSETS.IMAGES;

    case ENDPOINTS.ASSETS_IMAGES_DELETE:
      return `${BASE_URLS.ASSETS.IMAGES}/${endpoint.params.name}`;

    // REINSURERS
    case ENDPOINTS.REINSURERS_GET_ALL:
    case ENDPOINTS.REINSURERS_ADD_NEW:
      return BASE_URLS.REINSURERS;
    case ENDPOINTS.REINSURERS_CHECK_DATA:
      return `${BASE_URLS.REINSURERS}/check-reinsurer`;
    case ENDPOINTS.REINSURERS_GET_ONE:
      return `${BASE_URLS.REINSURERS}/${endpoint.params.id}`;
    case ENDPOINTS.REINSURERS_UPDATE_ONE:
      return `${BASE_URLS.REINSURERS}/${endpoint.params.id}`;
    case ENDPOINTS.REINSURERS_DELETE_ONE:
      return `${BASE_URLS.REINSURERS}/${endpoint.params.id}`;
    case ENDPOINTS.REINSURERS_RESTORE_ONE:
      return `${BASE_URLS.REINSURERS}/${endpoint.params.id}`;

    // MAPS
    case ENDPOINTS.MAPS_ADD_NEW:
    case ENDPOINTS.MAPS_GET_ALL:
      return BASE_URLS.MAPS;

    case ENDPOINTS.MAPS_GET_ONE:
    case ENDPOINTS.MAPS_UPDATE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_DELETE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_RESTORE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}/restore`;

    case ENDPOINTS.MAPS_DATA_POINTS_ADD_NEW:
    case ENDPOINTS.MAPS_DATA_POINTS_GET_ALL:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/data-points`;

    case ENDPOINTS.MAPS_DATA_POINTS_GET_ONE:
    case ENDPOINTS.MAPS_DATA_POINTS_UPDATE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/data-points/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_DATA_POINTS_DELETE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/data-points/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_DATA_POINTS_RESTORE_ONE:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/data-points/${endpoint.params.id}/restore`;

    case ENDPOINTS.MAPS_DATA_POINTS_GET_ALL_DOCUMENTATIONS_OF_A_MAPS_DATA_POINT:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/docs`;

    case ENDPOINTS.MAPS_DATA_POINTS_GET_ALL_REFERENCES_OF_A_MAPS_DATA_POINT:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}/reference-categories/${endpoint.params.categoryId}/references`;

    case ENDPOINTS.MAPS_DATA_POINTS_GENERATE_SECRETS:
      return `${BASE_URLS.MAPS}/generate-secrets`;

    case ENDPOINTS.MAPS_DATA_POINTS_GENERATE_PUBLIC_API_KEY:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}/keys/generate`;

    case ENDPOINTS.MAPS_DATA_POINTS_SAVE_GENERATED_PUBLIC_KEY:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.MAPS_DATA_POINTS_GET_SPECIFIC_GENERATED_KEY:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_DATA_POINTS_GET_ALL_GENERATED_KEYS:
      return `${BASE_URLS.MAPS}/${endpoint.params.id}/keys`;

    case ENDPOINTS.MAPS_DATA_POINTS_DELETE_KEY:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/keys/${endpoint.params.id}`;

    case ENDPOINTS.MAPS_DATA_POINTS_RESTORE_KEY:
      return `${BASE_URLS.MAPS}/${endpoint.params.mapId}/keys/${endpoint.params.id}/restore`;

    //PARTNERS
    case ENDPOINTS.PARTNERS_ADD_NEW:
    case ENDPOINTS.PARTNERS_GET_ALL:
      return BASE_URLS.PARTNERS;
    case ENDPOINTS.PARTNERS_UPDATE_ONE:
      return `${BASE_URLS.PARTNERS}/${endpoint.params.id}`;
    case ENDPOINTS.PARTNERS_GET_AGENT_INFORMATION:
      return `${BASE_URLS.PARTNERS}/foms/agent-info`;
    case ENDPOINTS.PARTNERS_GET_ALL_PARTIAL_DATA:
      return `${BASE_URLS.PARTNERS}/all/partial`;
    case ENDPOINTS.PARTNERS_GET_FILTERED_DATA:
      return `${BASE_URLS.PARTNERS}/filtered-partners/${endpoint.params.inputReportingPartner}/${endpoint.params.company_type}`;
    case ENDPOINTS.PARTNERS_RESTORE_ONE:
      return `${BASE_URLS.PARTNERS}/${endpoint.params.id}`;
    case ENDPOINTS.PARTNERS_DELETE_ONE:
      return `${BASE_URLS.PARTNERS}/${endpoint.params.id}`;
    case ENDPOINTS.PARTNERS_GET_ONE:
      return `${BASE_URLS.PARTNERS}/${endpoint.params.id}`;
    case ENDPOINTS.PARTNERS_CHECK_VALIDITY:
      return `${BASE_URLS.PARTNERS}/check-partner`;
    case ENDPOINTS.PARTNERS_VIEW_HIERARCHY:
      return `${BASE_URLS.PARTNERS}/hierarchy/${endpoint.params.id}`;

    // BUSINESS
    // POLICIES
    case ENDPOINTS.POLICIES_GET_ALL:
    case ENDPOINTS.POLICIES_ADD_NEW:
      return BASE_URLS.POLICIES;
    case ENDPOINTS.POLICIES_GET_ALL_FOR_CLAIMS:
      return `${BASE_URLS.POLICIES}/claim-policies`;
    case ENDPOINTS.POLICIES_GET_ALL_CLAIM_POLICY_LIST:
      return `${BASE_URLS.POLICIES}/claim-policy-list`;
    case ENDPOINTS.POLICIES_GET_AGENT_DATA:
      return `${BASE_URLS.POLICIES}/agent-data`;
    case ENDPOINTS.POLICIES_GET_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_UPDATE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_DELETE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_RESTORE_ONE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}`;
    case ENDPOINTS.POLICIES_UPDATE_STATUS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/status`;
    case ENDPOINTS.POLICIES_GET_ALL_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/comments`;
    case ENDPOINTS.POLICIES_ADD_COMMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/comment`;
    case ENDPOINTS.POLICIES_GET_ALL_PROPOSAL_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/comments`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL_COMMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/comment`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL_CREDIT_REQUEST_UPDATES:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/credit-request-updates`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL_CREDIT_REQUEST_COMMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/credit-request-comment`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL_CONFIGURATIONS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/configurations`;
    case ENDPOINTS.POLICIES_CHANGE_COMMISSIONS_AND_CHARGES:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/update-commissions-charges`;
    case ENDPOINTS.POLICIES_UPDATE_PREMIUM_WORKING_DETAILS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/update-premium-working-details`;
    case ENDPOINTS.POLICIES_UPDATE_PREMIUM_WORKING_DETAILS_MED_GROUP:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/update-premium-working-details-med-group`;
    case ENDPOINTS.POLICIES_UPDATE_SELECTED_SECTIONS_TRAVELS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/update-selected-sections-travels`;
    case ENDPOINTS.POLICIES_UPDATE_PRODUCT_DETAILS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/product-info`;
    case ENDPOINTS.POLICIES_ADD_PREMIUM_WORKING_INTERNAL_COMMENT:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/internal-comment-premium-working`;
    case ENDPOINTS.POLICIES_GET_ALL_PREMIUM_WORKING_INTERNAL_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/premium-working-internal-comments`;
    case ENDPOINTS.POLICIES_ASSIGN_AUTHORIZER:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/assign-authorizer`;
    case ENDPOINTS.POLICIES_ASSIGN_PROPOSAL_AUTHORIZER:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/assign-authorizer`;
    case ENDPOINTS.POLICIES_ASSIGN_RENEWAL_AUTHORIZER:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/assign-authorizer`;
    case ENDPOINTS.POLICIES_ASSIGN_CANCEL_POLICY_AUTHORIZER:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy-cancel/assign-authorizer`;
    case ENDPOINTS.POLICIES_GET_ALL_AUTHORIZERS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/get-authorizers`;
    case ENDPOINTS.POLICIES_GET_ALL_PROPOSAL_AUTHORIZERS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/get-authorizers`;
    case ENDPOINTS.POLICIES_GET_ALL_POLICY_CANCEL_AUTHORIZERS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy-cancel/get-authorizers`;
    case ENDPOINTS.POLICIES_ADD_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/authorization-comment`;
    case ENDPOINTS.POLICIES_GET_ALL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/authorization-step-comments`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/authorization-comment`;
    case ENDPOINTS.POLICIES_ADD_RENEWAL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/authorization-comment`;
    case ENDPOINTS.POLICIES_ADD_POLICY_CANCEL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy-cancel/authorization-comment`;
    case ENDPOINTS.POLICIES_GET_PROPOSAL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/authorization-comments`;
    case ENDPOINTS.POLICIES_GET_RENEWAL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/authorization-comments`;
    case ENDPOINTS.POLICIES_GET_POLICY_CANCEL_AUTHORIZATION_STEP_COMMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/policy-cancel/authorization-comments`;
    case ENDPOINTS.POLICIES_PUBLISH_QUOTATION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/publish-quotation`;
    case ENDPOINTS.POLICIES_PUBLISH_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/publish-proposal`;
    case ENDPOINTS.POLICIES_RENEWAL_PUBLISH_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/publish-proposal`;
    case ENDPOINTS.POLICIES_CANCEL_POLICY:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/cancel-policy`;
    case ENDPOINTS.POLICIES_GET_SCHEDULE_TEMPLATE:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/schedule-template`;
    case ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/documents/verify`;
    case ENDPOINTS.POLICIES_ADD_PROPOSAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal`;
    case ENDPOINTS.POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/verify`;
    case ENDPOINTS.POLICIES_PROPOSAL_RESUBMISSION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/resubmit`;
    case ENDPOINTS.POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/proposal/raised-requirements`;
    case ENDPOINTS.POLICIES_UPDATE_PROPOSAL_RENEWAL:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/proposal`;
    case ENDPOINTS.POLICIES_UPDATE_RENEWAL_PREMIUM_WORKING_DETAILS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/renewal/update-premium-working-details`;
    case ENDPOINTS.POLICIES_GET_MEDICAL_PREMIUMS:
      return `${BASE_URLS.POLICIES}/medical-premium`;
    case ENDPOINTS.POLICIES_GET_MEDICAL_GROUP_PREMIUMS:
      return `${BASE_URLS.POLICIES}/medical-group-premium`;
    case ENDPOINTS.POLICIES_GET_ANNUAL_PREMIUM:
      return `${BASE_URLS.POLICIES}/annual-premium`;
    case ENDPOINTS.POLICIES_GET_RI_LIMITS:
      return `${BASE_URLS.POLICIES}/${endpoint.params.id}/ri-limits`;

    // COVER NOTES
    case ENDPOINTS.COVER_NOTES_GET_ALL:
    case ENDPOINTS.COVER_NOTES_ADD_NEW:
      return BASE_URLS.COVER_NOTES;
    case ENDPOINTS.COVER_NOTES_GET_ONE:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;
    case ENDPOINTS.COVER_NOTES_UPDATE_ONE:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;
    case ENDPOINTS.COVER_NOTES_CANCEL_ONE:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;
    case ENDPOINTS.COVER_NOTES_UPDATE_ONE_BY_POLICY_ID:
      return `${BASE_URLS.COVER_NOTES}/${endpoint.params.id}`;

    // CUSTOMERS
    case ENDPOINTS.CUSTOMERS_GET_ALL:
    case ENDPOINTS.CUSTOMERS_ADD_NEW:
      return BASE_URLS.CUSTOMERS;
    case ENDPOINTS.CUSTOMERS_GET_ONE_BY_NIC:
      return `${BASE_URLS.CUSTOMERS}/nic`;
    case ENDPOINTS.CUSTOMERS_GET_ONE:
      return `${BASE_URLS.CUSTOMERS}/${endpoint.params.id}`;
    case ENDPOINTS.CUSTOMERS_UPDATE_ONE:
      return `${BASE_URLS.CUSTOMERS}/${endpoint.params.id}`;

    // SETUP - EXTERNAL SYSTEMS
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}/reference-categories`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS}/${endpoint.params.id}`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL:
      return BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/generate`;

    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE:
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}`;
    case ENDPOINTS.SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE:
      return `${BASE_URLS.SETUP.EXTERNAL_SYSTEMS_KEYS}/${endpoint.params.id}/restore`;

    // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.CLIENT_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.CLIENT_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.EXTERNAL_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.EXTERNAL_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //

    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
    // REFERENCES - REFERENCE_CATEGORIES
    case ENDPOINTS.IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCE_CATEGORIES}/${endpoint.params.id}/references`;

    // REFERENCES - REFERENCES
    case ENDPOINTS.IAM_REFERENCES_FIELDS_GET_VALUE:
      return `${BASE_URLS.REFERENCES.IAM_REFERENCES}/${endpoint.params.id}/fields/${endpoint.params.field_id}/value`;
    // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //

    // GATEWAYS
    case ENDPOINTS.GATEWAYS_GET_ALL:
      return `${BASE_URLS.GATEWAYS}`;
    case ENDPOINTS.GATEWAYS_GET_ONE:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}`;
    case ENDPOINTS.GATEWAYS_ENABLE:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}/enable`;
    case ENDPOINTS.GATEWAYS_DISABLE:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}/disable`;
    case ENDPOINTS.GATEWAYS_SANDBOX_ENABLE:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}/sandbox/enable`;
    case ENDPOINTS.GATEWAYS_SANDBOX_DISABLE:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}/sandbox/disable`;
    case ENDPOINTS.GATEWAY_UPDATE_CONFIG:
      return `${BASE_URLS.GATEWAYS}/${endpoint.params.id}/configuration`;

    // CHECKOUTS
    case ENDPOINTS.CHECKOUT_ALL_ALLOWED_PAYMENT_METHODS:
      return `${BASE_URLS.CHECKOUT}/payment-methods`;
    case ENDPOINTS.CHECKOUT_GENERATE_HASH:
      return `${BASE_URLS.CHECKOUT}/hash`;
    case ENDPOINTS.CHECKOUT_PAYHERE_DETAILS:
      return `${BASE_URLS.CHECKOUT}/payhere`;

    // TRANSACTIONS
    case ENDPOINTS.TRANSACTIONS_GET_ALL:
      return `${BASE_URLS.TRANSACTIONS}/`;
    case ENDPOINTS.TRANSACTION_CREATE_TRANSACTION:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/`;
    case ENDPOINTS.RECEIPTS_SEND_BODIES_TO_TRANSACTION:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/bodies`;
    case ENDPOINTS.TRANSACTION_CREATE_RECEIPT:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/receipt`;
    case ENDPOINTS.INITIALIZE_TRANSACTION_CREATE:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/initialize`;
    case ENDPOINTS.GET_TRANSACTION_BY_ORDER_ID:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/order/${endpoint.params.orderID}`;
    case ENDPOINTS.TRANSACTION_UPDATE_TRANSACTION_STATUS:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/${endpoint.params.id}`;
    case ENDPOINTS.GET_TRANSACTION:
      return `${BASE_URLS.PUBLIC_TRANSACTIONS}/${endpoint.params.transactionId}`;

    //WORKFLOWS
    case ENDPOINTS.WORKFLOWS_ADD_NEW:
    case ENDPOINTS.WORKFLOWS_GET_ALL:
      return BASE_URLS.WORKFLOWS;
    case ENDPOINTS.WORKFLOWS_UPDATE_ONE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}`;
    case ENDPOINTS.WORKFLOWS_RESTORE_ONE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}`;
    case ENDPOINTS.WORKFLOWS_DELETE_ONE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}`;
    case ENDPOINTS.WORKFLOWS_GET_ONE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}`;
    case ENDPOINTS.WORKFLOWS_DISABLE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}/disable`;
    case ENDPOINTS.WORKFLOWS_ENABLE:
      return `${BASE_URLS.WORKFLOWS}/${endpoint.params.id}/enable`;

    default:
      return '';
  }
};

export const generateURL = (endpoint: EndpointParams): string => {
  const path = getURLPath(endpoint);
  const apiVersion = endpoint.version ? `/v${endpoint.version}` : '';

  return `/api${apiVersion}${path}`;
};

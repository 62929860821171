export enum MODULES {
  IDENTITIES = 'IDENTITIES',
  APPS = 'APPS',
  CONNECTIONS = 'CONNECTIONS',
  MODULES = 'MODULES',
  NOTICES = 'NOTICES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MESSAGES = 'MESSAGES',
  LOGS = 'LOGS',
  AUTHORIZATION_PERMISSIONS = 'AUTHORIZATION_PERMISSIONS',
  AUTHORIZATION_ROLES = 'AUTHORIZATION_ROLES',
  AUTHORIZATION_PRIVILEGES = 'AUTHORIZATION_PRIVILEGES',
  STORAGE_DRIVE = 'STORAGE_DRIVE',
  STORAGE_RECYCLE_BIN = 'STORAGE_TRASH',
  REFERENCES = 'REFERENCES',
  INTEGRATIONS = 'INTEGRATIONS',
  SETUP = 'SETUP',

  // Business
  POLICIES = 'POLICIES',
  CUSTOMERS = 'CUSTOMERS',
  REPORTS = 'REPORTS',
  COVER_NOTES = 'COVER_NOTES',
  ENDORSEMENTS = 'ENDORSEMENTS',

  //Claims
  CLAIMS = 'CLAIMS',
  ASSESSORS = 'ASSESSORS',
  ASSESSMENTS = 'ASSESSMENTS',
  INCIDENTS = 'INCIDENTS',

  //BOMS RELATED
  AGREEMENTS = 'AGREEMENTS',
  PRODUCTS = 'PRODUCTS',
  REINSURERS = 'REINSURERS',
  PARTNERS = 'PARTNERS',
  MAPS = 'MAPS',
  WORKFLOWS = 'WORKFLOWS',

  //FINANCE RELATED
  BRANCHES = 'BRANCHES',
  ACCOUNTS = 'ACCOUNTS',
  GATEWAYS = 'GATEWAYS',
  CONTROLS = 'CONTROLS',
  TRANSACTIONS = 'TRANSACTIONS',
  RECEIPTS = 'RECEIPTS',
  PAYMENTS = 'PAYMENTS',
  EXCHANGE_RATES = 'EXCHANGE_RATES',

  //ACCOUNTING RELATED
  CHART_OF_ACCOUNTS = 'CHART_OF_ACCOUNTS',
  ENTRIES = 'ENTRIES',
  INVOICES = 'INVOICES',

  //DATABASE
  BLACKLIST = 'BLACKLIST',
}

export enum MODULE_GROUPS {
  AUTHORIZATION = 'AUTHORIZATION',
  INTEGRATIONS = 'INTEGRATIONS',
  STORAGE = 'STORAGE',
  REFERENCES = 'REFERENCES',
}

export enum MODULE_STATUSES {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  HIDDEN = 'hidden',
  DISABLE = 'disable',
}

import { ENVIRONMENTS, IWebEnvironment } from '@shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IWebEnvironment = {
  env: ENVIRONMENTS.QA,
  production: false,
  isDebugMode: true,
  isSentryDebugMode: false,
  VERSION: require('../../../../package.json').version,
  CORE_VERSION: require('../../../../package.json').coreversion,
  API_URL: window.location.origin || 'https://qa.boms.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  WEB_URL: window.location.origin || 'https://qa.boms.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  WEB_DOMAIN: window.location.hostname || 'qa.boms.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  API_DOMAIN: window.location.hostname || 'qa.boms.jic.echonlabs.com', // NOTE: New Change is only for Base Neo project
  GOOGLE_KEY: 'AIzaSyDQt9fXkRh9GZz4gEA-vQuC5gc0MeOb6gY',
  JITSI_DOMAIN: 'jitsi.echonlabs.com',
  JITSI_JWT_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJteV9zZXJ2ZXIxIiwiaXNzIjoibXlfd2ViX2NsaWVudCIsInN1YiI6Im1lZXQuaml0c2kiLCJyb29tIjoiKiJ9.xX-0FYCjH2zsLQGBwJZK9Ggjn70nIsL2vHEx86jXV0I',
  SENTRY_DSN: process.env.NX_WEB_SENTRY_DSN,
};
